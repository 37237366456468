import axios from "axios";
import { IDataFetchResult } from "../models";
import { AuthRefreshToken } from "./request";

export function getAccessToken() {
  return localStorage.getItem("access_token");
}

export function getRefreshToken() {
  return localStorage.getItem("refresh_token");
}

export function setAccessToken(token: string) {
  localStorage.setItem("access_token", token);
}

export function setRefreshToken(token: string) {
  localStorage.setItem("refresh_token", token);
}

export function getTokenHeader() {
  return {
    Authorization: `Bearer ${getAccessToken()}`,
  };
}

export function isTokensValid(): boolean {
  var accessToken = getAccessToken();
  var refreshToken = getRefreshToken();
  return (
    accessToken &&
    refreshToken &&
    (isTokenValid(accessToken) || isTokenValid(refreshToken))
  );
}

export function isTokenValid(token: string): boolean {
  if (!token) return false;
  return Date.now() < parseJwt(token)["exp"] * 1000;
}

function parseJwt(token: string) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export function removeTokens() {
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
}

export function updateToken(callback: IDataFetchResult) {
  axios
    .post(AuthRefreshToken(), { token: getRefreshToken() })
    .then(function (response) {
      setAccessToken(response["data"]["accessToken"]);
      if (callback != null) {
        callback.onResolve(response["data"]);
      }
    })
    .catch(function (error) {
      if (callback != null) {
        callback.onError(error);
      }
      if (error.response && error.response.status == 401) {
        removeTokens();
        window.open("login", "_self");
      }
    });
}
