export function isOperator(): boolean {
  return hasPermission("op");
}

export function hasPermission(permId: string): boolean {
  var permJson = localStorage.getItem("permissions");
  if (!permJson) return false;

  var permissions = JSON.parse(permJson) as string[];
  if (permissions.includes("op")) return true;
  return permissions.includes(permId);
}

export function setPermissions(permissions: string[]) {
  localStorage.setItem("permissions", JSON.stringify(permissions));
}
