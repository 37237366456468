import axios from "axios";
import { useCallback, useState } from "react";
import { IDataFetchResult } from "../models";
import { getTokenHeader, updateToken } from "./jwt";

const useDataSender = () => {
  const [isSending, setIsSending] = useState(false);

  const sendData = useCallback(
    (
      url: string,
      data: any,
      sendResult: IDataFetchResult,
      useToken?: boolean
    ) => {
      sendDataDirectly(url, data, sendResult, useToken, setIsSending);
    },
    [setIsSending]
  );

  return {
    sendData,
    isSending,
  };
};

function onError(
  dataCallback: IDataFetchResult,
  error: any,
  sendStateSetter?: (value: React.SetStateAction<boolean>) => void
) {
  if (sendStateSetter != undefined) sendStateSetter(false);
  if (dataCallback != null) dataCallback.onError(error);
}

export function sendDataDirectly(
  url: string,
  data: any,
  sendResult: IDataFetchResult,
  useToken?: boolean,
  sendStateSetter?: (value: React.SetStateAction<boolean>) => void
) {
  if (useToken == undefined) useToken = true;

  if (sendStateSetter != undefined) sendStateSetter(true);
  axios
    .post(url, data, useToken && { headers: getTokenHeader() })
    .then(function (response) {
      if (sendStateSetter != undefined) sendStateSetter(false);

      if (sendResult != null) {
        sendResult.onResolve(response["data"]);
      }
    })
    .catch(function (error) {
      if (error.response && error.response.status == 401 && useToken) {
        updateToken({
          onResolve() {
            if (sendStateSetter != undefined) sendStateSetter(false);
            sendDataDirectly(url, data, sendResult, useToken, sendStateSetter);
          },
          onError(error) {
            onError(sendResult, error, sendStateSetter);
          },
        });
      } else {
        onError(sendResult, error, sendStateSetter);
      }
    });
}

export default useDataSender;
