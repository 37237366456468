import { Input, Tooltip } from "antd";

interface InputCurrencyProps {
  style: React.CSSProperties;
  value: string;
  placeHolder?: string;
  onChange: (value: string) => void;
}

//const formatNumber = (value: number) => new Intl.NumberFormat().format(value);
const moneyFormatter = new Intl.NumberFormat("tr-TR", {
  style: "currency",
  currency: "TRY",
});

export const InputCurrency = (props: InputCurrencyProps) => {
  const { value, onChange } = props;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = e.target;
    const reg = /^-?\d*(\.\d*)?$/;
    if (reg.test(inputValue) || inputValue === "" || inputValue === "-") {
      onChange(inputValue);
    }
  };

  // '.' at the end or only '-' in the input box.
  const handleBlur = () => {
    let valueTemp = value;
    if (value.charAt(value.length - 1) === "." || value === "-") {
      valueTemp = value.slice(0, -1);
    }
    onChange(valueTemp.replace(/0*(\d+)/, "$1"));
  };

  const title = value ? (
    <span className="numeric-input-title">
      {value !== "-" ? moneyFormatter.format(Number(value)) : "-"}
    </span>
  ) : (
    //moneyFormatter.format(Number(0))
    "Kuruşu nokta ile ayırın"
  );

  return (
    <Tooltip
      trigger={["focus"]}
      title={title}
      placement="topLeft"
      overlayClassName="numeric-input"
    >
      <Input
        {...props}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={props.placeHolder}
        maxLength={25}
      />
    </Tooltip>
  );
};
