import { Button, Checkbox, Form, Input } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";

export interface ModelFormLogin {
  onFinish: (values: any) => void;
  loading: boolean;
}

export const FormLogin: React.FunctionComponent<ModelFormLogin> = (
  props: ModelFormLogin
) => {
  return (
    <Form
      name="normal_login"
      className="login-form"
      initialValues={{ remember: true }}
      onFinish={props.onFinish}
    >
      <Form.Item
        name="username"
        rules={[{ required: true, message: "Kullanıcı adı gir!" }]}
      >
        <Input
          prefix={<UserOutlined className="site-form-item-icon" />}
          placeholder="Kullanıcı adı"
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[{ required: true, message: "Şifre gir!" }]}
      >
        <Input
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="Şifre"
        />
      </Form.Item>
      <Form.Item name="remember" valuePropName="checked">
        <Checkbox>Beni hatırla</Checkbox>
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="login-form-button"
          loading={props.loading}
        >
          Giriş Yap
        </Button>
      </Form.Item>
    </Form>
  );
};
