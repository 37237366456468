import { Select } from "antd";

import React from "react";
import { lowerCase } from "../../utils/textutils";

const { Option } = Select;

export interface ArgsSelectBasic {
  id: string;
  loading: boolean;
  placeholder: string;
  listEntries: ISelectOptionEntry[];
  onClear(): void;
  onSelect(selected: ISelectOptionEntry): void;
  minWidth?: number;
  defaultValueId?: string;
}

export interface ISelectOptionEntry {
  key: string;
  displayName: string;
}

export const SelectBasic: React.FunctionComponent<ArgsSelectBasic> = (
  props: ArgsSelectBasic
) => {
  return (
    <Select
      showSearch
      allowClear
      style={{ minWidth: props.minWidth ? props.minWidth : 160 }}
      placeholder={props.placeholder}
      optionFilterProp="children"
      loading={props.loading}
      defaultValue={props.defaultValueId}
      onClear={() => {
        props.onClear();
      }}
      onSelect={(id: string) => {
        var selected: ISelectOptionEntry;
        props.listEntries.forEach((element) => {
          if (element.key == id) selected = element;
        });
        props.onSelect(selected);
      }}
      filterOption={(input, option) => {
        return lowerCase(option!.children as unknown as string).includes(
          lowerCase(input)
        );
      }}
      filterSort={(optionA, optionB) => {
        return (optionA!.children as unknown as string)
          .toLowerCase()
          .localeCompare(
            (optionB!.children as unknown as string).toLowerCase()
          );
      }}
    >
      {props.listEntries &&
        props.listEntries.map((item) => (
          <Option key={item.key}>{item.displayName}</Option>
        ))}
    </Select>
  );
};
