import { notification } from "antd";

export enum NotificationType {
  Success = "success",
  Info = "info",
  Warning = "warning",
  Error = "error",
}

/*export function SendNotification(type : NotificationType, message : string, description : string){
    notification[type]({
        message: message,
        description: description
      })
}*/

export class Notification {
  private type: NotificationType;
  private message: string;
  private description: string;
  private duration: number;

  constructor(type: NotificationType, message: string) {
    if (type == null) throw EvalError("NotificationType cannot be null");
    this.type = type;
    this.message = message;
    this.duration = 3;
  }

  public setDescription(description: string): Notification {
    this.description = description;
    return this;
  }

  public setDuration(duration: number): Notification {
    this.duration = duration;
    return this;
  }

  public send() {
    notification[this.type]({
      message: this.message,
      description: this.description,
      duration: this.duration,
    });
  }
}
