import axios from "axios";
import { useCallback, useState } from "react";
import { IDataFetchResult } from "../models";
import { getTokenHeader, updateToken } from "./jwt";

const useDataFetcher = () => {
  const [isFetching, setIsFetching] = useState(false);

  const fetchData = useCallback(
    (url: string, fetchResult: IDataFetchResult, useToken?: boolean) => {
      fetchDataDirectly(url, fetchResult, useToken, setIsFetching);
    },
    [setIsFetching]
  );

  return {
    fetchData,
    isFetching,
  };
};

function onError(
  dataCallback: IDataFetchResult,
  error: any,
  fetchStateSetter?: (value: React.SetStateAction<boolean>) => void
) {
  if (fetchStateSetter != undefined) fetchStateSetter(false);
  if (dataCallback != null) dataCallback.onError(error);
}

export function fetchDataDirectly(
  url: string,
  fetchResult: IDataFetchResult,
  useToken?: boolean,
  fetchStateSetter?: (value: React.SetStateAction<boolean>) => void
) {
  if (useToken == undefined) useToken = true;

  if (fetchStateSetter != undefined) fetchStateSetter(true);
  axios
    .get(url, useToken && { headers: getTokenHeader() })
    .then(function (response) {
      if (fetchStateSetter != undefined) fetchStateSetter(false);

      if (fetchResult != null) {
        fetchResult.onResolve(response["data"]);
      }
    })
    .catch(function (error) {
      if (error.response && error.response.status == 401 && useToken) {
        updateToken({
          onResolve() {
            if (fetchStateSetter != undefined) fetchStateSetter(false);
            fetchDataDirectly(url, fetchResult, useToken, fetchStateSetter);
          },
          onError(error) {
            onError(fetchResult, error, fetchStateSetter);
          },
        });
      } else {
        onError(fetchResult, error, fetchStateSetter);
      }
    });
}

export default useDataFetcher;
