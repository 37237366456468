import { DeleteOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Input, List, Select, Upload, UploadProps } from "antd";
import { UploadFile } from "antd/lib/upload/interface";
import React, { useState } from "react";
import { IStockCategory } from "../../models";
import { Notification, NotificationType } from "../../utils/notification";
import { ViewStockCategoryImage } from "../../utils/request";
import { Image } from "antd";

export interface ArgsListStockCategories {
  title: string;
  loading: boolean;
  categories: IStockCategory[];
  add(value: string, uploadFile: UploadFile): void;
  remove(id: string): void;
}

export const ListStockCategories: React.FunctionComponent<
  ArgsListStockCategories
> = (props: ArgsListStockCategories) => {
  const [logoFile, setLogoFile] = useState<any>();
  const inputRef = React.useRef();

  const logoUploadProbs: UploadProps = {
    onRemove: (file) => {
      setLogoFile(null);
    },
    beforeUpload: (file) => {
      setLogoFile(file);
      return false;
    },
    maxCount: 1,
    accept: "image/png, image/jpeg, image/svg+xml",
  };

  function onAdd() {
    if (!logoFile) {
      new Notification(NotificationType.Error, "Logo seçmelisin")
        .setDuration(2)
        .send();
      return;
    }

    var displayName = (inputRef.current as any).input.value;
    if (!displayName) {
      new Notification(NotificationType.Error, "Kategori ismi girmelisin")
        .setDuration(2)
        .send();
      return;
    }

    const isPDF =
      logoFile.type == "image/png" ||
      logoFile.type == "image/jpeg" ||
      logoFile.type == "image/svg+xml";
    if (!isPDF) {
      new Notification(NotificationType.Error, "izin verilmeyen dosya formatı.")
        .setDuration(2)
        .send();
      return;
    }

    const sizeCheck = logoFile.size < 1024 * 1024;
    if (!sizeCheck) {
      new Notification(
        NotificationType.Error,
        `Dosya boyutu 1 MB'den büyük olamaz`
      )
        .setDuration(2)
        .send();
      return;
    }

    props.add(displayName, logoFile);
  }

  return (
    <div>
      <div className="flex space-x-1 mb-4 items-center">
        <Input
          ref={inputRef}
          placeholder="Kategori ismi gir"
          className="mb-4"
          style={{ maxWidth: 200 }}
        />
        <Upload {...logoUploadProbs}>
          <Button icon={<UploadOutlined />}>Logo Seç</Button>
        </Upload>
        <Button loading={props.loading} type="primary" onClick={onAdd}>
          Ekle
        </Button>
      </div>

      <List
        bordered
        dataSource={props.categories}
        loading={props.loading}
        size="small"
        style={{ overflow: "auto", height: "420px" }}
        renderItem={(item) => (
          <List.Item>
            <Button
              danger
              icon={<DeleteOutlined />}
              onClick={() => {
                props.remove(item.id);
              }}
            />
            <List.Item.Meta className="m-1" title={item.displayName} />

            <Image width={50} src={ViewStockCategoryImage(item.logoPath)} />
          </List.Item>
        )}
      />
    </div>
  );
};
