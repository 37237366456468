import { Button, Input } from "antd";
import React, { useState } from "react";
import { IStockCategory, IStockProduct } from "../models";
import { Notification, NotificationType } from "../utils/notification";
import { COLORS_AS_SELECT_OPTION } from "../utils/utils";
import { ISelectOptionEntry, SelectBasic } from "./select/SelectBasic";

export interface ArgsCreateStockProduct {
  loading: boolean;
  categories: IStockCategory[];
  onCreate(editId: string, displayName: string, category: IStockCategory): void;
  defaultCreateBarProbs?: IStockProduct;
}

export const CreateStockProduct: React.FunctionComponent<
  ArgsCreateStockProduct
> = (props: ArgsCreateStockProduct) => {
  const [selectedCategoryId, setSelectedCategoryId] = useState<string>(
    props.defaultCreateBarProbs?.categoryId
  );

  const inputRef = React.useRef();

  function categoriesToSelectEntry(): ISelectOptionEntry[] {
    var list = [] as ISelectOptionEntry[];
    props.categories.forEach((element) => {
      list.push({ key: element.id, displayName: element.displayName });
    });
    return list;
  }

  function onAdd() {
    var displayName = (inputRef.current as any).input.value;

    if (!displayName) {
      new Notification(NotificationType.Error, "Ürün ismi girmelisin")
        .setDuration(2)
        .send();
      return;
    }

    if (!selectedCategoryId) {
      new Notification(NotificationType.Error, "Kategori seçmelisin")
        .setDuration(2)
        .send();
      return;
    }

    var category = null as IStockCategory;
    props.categories.forEach((element) => {
      if (element.id == selectedCategoryId) category = element;
    });

    if (!category) {
      new Notification(
        NotificationType.Error,
        "Kategori veritabanında bulunamadı"
      )
        .setDuration(2)
        .send();
      return;
    }

    props.onCreate(props.defaultCreateBarProbs?.id, displayName, category);
  }

  return (
    <div className="flex space-x-1 mb-4 items-center">
      <Input
        ref={inputRef}
        placeholder="Ürün ismi gir"
        defaultValue={props.defaultCreateBarProbs?.displayName}
        className="mb-4"
        style={{ maxWidth: 200 }}
      />

      {
        <SelectBasic
          id="category"
          loading={false}
          placeholder="Kategori"
          listEntries={categoriesToSelectEntry()}
          defaultValueId={props.defaultCreateBarProbs?.categoryId}
          onClear={() => {
            setSelectedCategoryId(null);
          }}
          onSelect={(selected: ISelectOptionEntry) => {
            setSelectedCategoryId(selected.key);
          }}
        />
      }

      <Button loading={props.loading} type="primary" onClick={onAdd}>
        {props.defaultCreateBarProbs ? "Düzenle" : "Ekle"}
      </Button>
    </div>
  );
};
