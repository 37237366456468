import { List, Modal, Popconfirm } from "antd";
import React, { useState } from "react";
import { IStockCategory, IStockProduct } from "../../models";
import { getColorSelectOptionEntry } from "../../utils/utils";
import { CreateStockProduct } from "../BarCreateStockProduct";

export interface ArgsListStockProducts {
  title: string;
  loading: boolean;
  categories: IStockCategory[];
  products: IStockProduct[];
  add(editId: string, displayName: string, category: IStockCategory): void;
  remove(id: string): void;
}

export const ListStockProducts: React.FunctionComponent<
  ArgsListStockProducts
> = (props: ArgsListStockProducts) => {
  const [editProduct, setEditProduct] = useState<IStockProduct>();

  function getCategoryByID(id: string): IStockCategory {
    var category = null as IStockCategory;
    props.categories.forEach((element) => {
      if (element.id == id) category = element;
    });
    return category;
  }

  function buildCategoryDescription(product: IStockProduct) {
    var categoryDisplayName = getCategoryByID(product.categoryId)?.displayName;
    if (!categoryDisplayName)
      return (
        <span className="font-bold" style={{ color: "red" }}>
          Atanmadı
        </span>
      );
    return <span>{categoryDisplayName}</span>;
  }

  function onCreate(
    editId: string,
    displayName: string,
    category: IStockCategory
  ) {
    setEditProduct(null);
    props.add(editId, displayName, category);
  }

  return (
    <div className="flex flex-col">
      <CreateStockProduct
        loading={props.loading}
        categories={props.categories}
        onCreate={onCreate}
      />

      <List
        bordered
        dataSource={props.products}
        loading={props.loading}
        style={{ overflow: "auto", height: "420px" }}
        renderItem={(item) => (
          <List.Item
            actions={[
              <a
                onClick={() => {
                  setEditProduct(item);
                }}
              >
                Düzenle
              </a>,
              <Popconfirm
                title="Ürünü silersen bağlı tüm stoklar silinecek"
                okText="Sil"
                cancelText="Kapat"
                onConfirm={() => props.remove(item.id)}
              >
                <a style={{ color: "red" }}>Sil</a>
              </Popconfirm>,
            ]}
          >
            <List.Item.Meta
              className="m-1"
              title={item.displayName}
              description={buildCategoryDescription(item)}
            />
          </List.Item>
        )}
      />

      {editProduct && (
        <Modal
          title={editProduct.displayName}
          closable={true}
          destroyOnClose={true}
          onCancel={() => {
            setEditProduct(null);
          }}
          visible={true}
          footer={null}
          style={{ minWidth: 600 }}
        >
          <CreateStockProduct
            loading={props.loading}
            categories={props.categories}
            onCreate={onCreate}
            defaultCreateBarProbs={editProduct}
          />
        </Modal>
      )}
    </div>
  );
};
